.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 2160px;
  box-sizing: border-box;
}

@media (min-height: 620px) {
  .container {
    height: 100%;
  }
}

@media (max-height: 619px) {
  .container {
    height: auto;
  }
}

.button {
  width: auto;
  min-width: 160px;
  border-radius: 6px;
}
