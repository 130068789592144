.menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  right: 0px;
  top: 75px;
  background: #ffffff;
  z-index: -1;
  box-shadow: 0 2px 6px rgb(128 145 192 / 40%);
}

.item {
  color: #22366d;
  font-weight: 500;
  font-size: 14px;
  height: 60px;
  padding-left: 23px;
  line-height: 16.41px;
  display: flex;
  align-items: center;
}

.item:hover {
  background: #f5f7f9;
}

.itemActive {
  background: #f5f7f9;
  border-left: 3px solid #4e78f6;
}

.btns {
  padding: 0 5px;
}
.btn {
  background: #f5f7f9;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000a19;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid transparent;
  box-sizing: border-box;
}

.btn:hover {
  cursor: pointer;
  background: #f5f7f9;
  border: 1px solid #d9dfec;
}
