.button {
  background-color: #f4f5f8;
  height: 48px;
  width: 48px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  transition: all 0.2s linear;
}
.button:hover {
  background-color: #d9dadd;
}

.button:disabled {
  opacity: 0.4;
  background-color: #f4f5f8;
}
