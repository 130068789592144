@media (min-width: 900px) and (max-width: 1093px) {
  .gridBtnMissionHandler .btnMissionHandler {
    font-size: 10px;
    padding: 3px 15px;
  }

  .gridBtnMissionHandler .btnMissionSelect {
    font-size: 11px;
  }

  .iconMussionHandler {
    width: 9px;
  }
}

@media (min-width: 900px) and (max-width: 966px) {
  .gridBtnMissionHandler .btnMissionHandler {
    padding: 3px 10px;
  }
}

@media (max-height: 950px) {
  .wrapper .cardMission {
    padding: 8px 8px 0 8px;
  }

  .gridMission {
    padding-top: 0;
  }

  .gridBtnMissionHandler .btnMissionHandler {
    padding: 3px 15px;
    font-size: 12px;
  }

  .gridBtnMissionHandler .btnMissionSelect {
    font-size: 11px;
  }
}
