.tableWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.customTableCell {
  display: flex;
  justify-content: left;
  padding: 0px 0px 20px 0px;
  border-bottom: 0;
  color: var(--text-light-gray, #9e9e9e);
}

.iconBox {
  display: flex;
  align-items: center;
}

.iconFlex {
  display: flex;
  padding-left: 9px;
}

.tableHeaderItem {
  align-items: center;
  display: flex;
  padding-left: 5px;
}

.tableHeaderItemSmall {
  align-items: center;
  display: flex;
  justify-content: center;
}

.emptyWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: rgba(99, 114, 154, 1);
}

.emptyTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 16px;
}

.emptyDescription {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: center;
  max-width: 330px;
}

.box {
  padding: 0px;
  display: flex;
  flex-direction: column;
  min-height: 0;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-width: 2160px;
  box-sizing: border-box;
}

.main {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1 1 auto;
  min-height: 0;
  min-width: 650px;
  padding-top: 40px;
}

.menuItemText {
  margin: 0 12px;
}

.menuItemIcon {
  margin: 0 12px;
}
