.manualModeMobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  /* background-image: url('./../../images/ImageManualMode.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%; */
}

.manualButton {
  width: 100%;
}
