.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-width: 2160px;
  box-sizing: border-box;
}

@media (min-height: 620px) {
  .container {
    height: 100%;
  }
}

@media (max-height: 619px) {
  .container {
    height: auto;
  }
}
