.title {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  color: #000a19cc;
  margin-top: 37px;
  margin-bottom: 38px;
  line-height: 25px;
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 29px;
  }
}
