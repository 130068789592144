.default {
  border: 1px solid #41c04e;
  color: #ffffff;

  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;

  background-color: #41c04e;
  padding: 0 10px;
  border-radius: 0px;

  display: flex;
  min-height: 24px;
  max-width: 250px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 300ms;
  width: 100%;
}
.default:hover {
  border: 1px solid rgb(45, 134, 54);
  background-color: rgb(45, 134, 54);
}
.default:disabled {
  border: 1px solid #41c04e;
  background-color: #41c04e;
  opacity: 0.5;
}

.default:active {
  border: 1px solid rgb(40, 119, 48);
  background-color: rgb(40, 119, 48);
}

.outline {
  border: 1px solid #1b2b4899;
  color: #1b2b4899;
  background-color: #ffffff;
  transition: none;
}
.outline:hover {
  border: 1px solid #1b2b4899;
  background-color: #ffffff;
  opacity: 0.95;
}
.outline:disabled {
  border: 1px solid #1b2b4899;
  background-color: #e3e3e3;
  opacity: 0.5;
}
.outline:active {
  border: 1px solid #1b2b4899;
  opacity: 0.9;
}

.standart {
  color: #ffffff;
  background-color: #41c04e;
  transition: none;
}
.standart:hover {
  opacity: 0.95;
}
.standart:disabled {
  opacity: 0.3;
}
.standart:active {
  opacity: 0.9;
}
.standart svg path {
  fill: #ffffff;
}

.normal {
  height: 35px;
  font-size: 15px;
}

.medium {
  height: 45px;
  font-size: 16px;
}

.loader {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  margin-right: 10px;
  width: 14px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
