.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-width: 2160px;
  box-sizing: border-box;
}

.buttonManual {
  width: 100%;
  height: 100%;
  border-radius: 11px;
  background-color: white;
}

.manualModeMobileWrapper {
  display: none;
}

@media only screen and (max-height: 550px) {
  .manualModeMobileWrapper {
    display: flex;
  }

  .manualMode {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .manualModeMobileWrapper {
    display: flex;
  }

  .manualMode {
    display: none;
  }
}

.video {
  width: 100%;
  height: 100%;
  transform-origin: bottom left;
  object-fit: fill;
}
@media screen and (width <= 600px) {
  .video {
    position: fixed;
    top: 0;
    left: 0;
    transform: rotate(90deg);
    width: 100vh;
    height: 100vw;
    z-index: 1;
    margin-top: -100vw;
    background-color: #000;
  }
}

@media screen and (height <= 550px) {
  .video {
    position: fixed;
    top: 0;
    left: 0;
    /* transform: rotate(90deg); */
    width: 100vw;
    z-index: 1;
  }
}
