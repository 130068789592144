.avatarText {
  color: #63729a;
  font-weight: 500;
}

.cutText {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 148px;
  white-space: nowrap;
  margin-left: 12px;
  font-weight: 500;
  color: #000a19;
}

.userMenuBlock {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 75px;
  max-width: 266px;
}
