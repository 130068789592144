.header {
  width: 100%;
  height: 75px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  box-shadow: 0 1px #dadde5;
}

.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 75px);
  max-height: calc(1080px - 75px);
  margin: 0 30px;
  align-items: center;
  background-color: white;
  padding-top: 75px;
  overflow: visible;
}
