@media only screen and (max-height: 450px) {
  .root {
    width: 553px;
    height: 327px;
    max-height: 800px;
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-height: 450px) {
  .root {
    width: 553px;
    height: 527px;
    max-height: 800px;
    display: flex;
    flex-direction: column;
  }
}

.content {
  flex: 1 1 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
