.searchInput {
  display: flex;
  position: relative;
  border-radius: 8px;
  background-color: #f4f5f8;
  margin-bottom: 10px;
  height: 48px;
}

.searchIconWrapper {
  padding-left: 20px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
