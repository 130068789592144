.root {
  position: relative;
  max-width: 600px;
  height: 700px;
  background: #fff;
  box-shadow: 0 4px 40px rgba(13, 28, 51, 0.16);
  outline: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.head .title {
  position: relative;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: rgba(0, 10, 25, 0.8);
  padding: 20px;
}

.textfield {
  margin-bottom: 35px;
}

.contentColumns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
  gap: 29px;
}

.selectFileBlock {
  display: flex;
  align-items: flex-end;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 17px;
  padding: 16px;
}

.btn {
  max-width: 160px;
}

.btnSelect {
  height: 40px;
  max-width: 152px;
  font-size: 14px;
  margin-left: 11px;
}

.textfieldFullWidth {
  width: 100%;
}

.error {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 12px;
  color: #dc664d;
  margin-top: 4px;
}

.modal {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}

.content {
  flex: 1 1 0;
  padding: 20px;
}

.geotiffDescription {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  color: #1b2b4899;
  margin-top: 4px;
}

.weekdays {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.additionalInfoHeader {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  margin: 10px 0px;
  color: #000a19cc;
}

.icon {
  margin-left: 15px;
}

.icon svg path {
  fill: #323232;
}

.showMore {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.settingsMeters .subtitle {
  font-size: 16px;
}

.errorMessage {
  color: #dc664d;
  font-size: 22px;
}
