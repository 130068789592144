.tableWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.menuItemIcon {
  margin: 0 12px;
}

.wrapperMode {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr;
}

.collapseIcon path {
  stroke: #424040;
}

.tableHeader {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  display: grid;
  height: 45px;
  font-family: 'Montserrat';
  min-height: 45px;
  box-shadow: 0px -1px 0px 0px rgba(218, 221, 229, 1) inset;
}

.tableHeaderItem {
  align-items: center;
  justify-content: center;
  display: flex;
  padding-left: 5px;
  font-family: 'Montserrat !important';
  font-size: 12px !important;
}

.tableCellItem {
  align-items: center;
  justify-content: center;
  display: flex;
  padding-left: 5px;
  font-family: 'Montserrat !important';
  font-size: 14px !important;
}

.tableHeaderItemSmall {
  align-items: center;
  display: flex;
  justify-content: center;
}

.emptyWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: rgba(99, 114, 154, 1);
}

.emptyTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 16px;
}

.emptyDescription {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: center;
  max-width: 330px;
}

.table {
  overflow: auto;
}

.collapseIcon path {
  stroke: #424040;
}
.menuItemIcon {
  margin: 0 12px;
}
