.filter {
  display: flex;
  position: relative;
  justify-content: center;
  color: #b7b7b7;
  border-radius: 8px;
  background-color: #f4f5f8;
  width: 100%;
  height: 48px;
}
