.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-width: 2160px;
  box-sizing: border-box;
}

.button {
  width: auto;
  min-width: 160px;
  border-radius: 6px;
}
