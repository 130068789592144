.button {
  writing-mode: vertical-rl;
  width: 44px !important;
  height: 132px !important;
  border-radius: 20px !important;
  border: 1px !important;
  background: rgba(255, 255, 255, 0.8) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  /* letter-spacing: -3px; */
  cursor: pointer;
}
.legend {
  max-width: 390px;
  border-radius: 20px;
  border: 1px;
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  margin-right: 10px;
}
