.emptyWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
}
.emptyTitle {
  font-size: 20px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0px;
}
.emptyDescription {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
}
