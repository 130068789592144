.container {
  width: 100%;
  height: 100%;
}
.selectBox {
  width: 100%;
  max-width: 280px;
}

.selectBoxData {
  width: 100%;
  max-width: 252px;
}
