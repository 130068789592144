@import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
@import 'mapbox-gl/dist/mapbox-gl.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', source-code-pro, Menlo, sans-serif;
  background: #fff;
}

code {
  font-family: 'Roboto', source-code-pro, Menlo, sans-serif;
}

.mapboxgl-canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  cursor: default;
}

.text-16-500 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.text-16-400 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.text-16-300 {
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
}

.text-12-400 {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(27, 43, 72, 0.6);
}

.text-14-600 {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  font-family: Montserrat;
  color: #424040;
}

.text-14-500 {
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  color: #000a19cc;
}

.text-14-400 {
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  color: #000a19cc;
}

.text-12-500 {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  font-family: Montserrat;
  color: #9e9e9e;
}

.text-light-14-400 {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #1b2b4899;
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.ol-zoom {
  top: auto;
  bottom: 20px;
  left: auto;
  right: 0.5em;
}

.MuiButtonBase-root {
  text-transform: none !important;
}

.ol-control-custom {
  background-color: transparent;
  border-radius: none;
}

.ol-control-custom-zoom {
  right: 0.5em;
  bottom: 20px;
}

.ol-control-custom-zoom .ol-control-custom-zoom-in {
  margin-bottom: 8px;
  background-image: url('images/icons/plus_zoom.svg');
  background-repeat: no-repeat;
  background-position: 50%;
}

.ol-control-custom-zoom .ol-control-custom-zoom-out {
  background-image: url('images/icons/minus_zoom.svg');
  background-repeat: no-repeat;
  background-position: 50% 55%;
}

.ol-control-custom button {
  height: 3em;
  width: 3em;
  border-radius: 11px;
  box-shadow: 0px 4px 36px 0px rgba(0, 0, 0, 0);
}

.ol-control-custom button:hover,
.ol-control-custom button:focus {
  text-decoration: none;
  outline: 1px solid var(--ol-subtle-foreground-color);
  color: var(--ol-foreground-color);
}

.ol-control-no-border button:hover,
.ol-control-no-border button:focus {
  outline: none;
  background: transparent;
}
