.wrapperIcon {
  position: relative;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  transform: translateX(-6px);
}

.wrapperIcon.gray {
  background-color: #f4f5f8;
}

.wrapperIcon.white {
  background-color: #ffffff;
}

.wrapperIcon .icon {
  position: absolute;
  top: 50%;
  right: -23px;
  transform: translateY(-40%);
}

.messageText {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
