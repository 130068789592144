.header {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  max-width: 2160px;
  margin: 0 30px;
  box-sizing: border-box;
}

.logo {
  text-decoration: none;
  cursor: pointer;
  height: 75px;
  display: flex;
  width: 150px;
}

.middle {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  max-width: 460px;
  height: 100%;
}

.middleLinksBlock {
  height: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: space-around;
  max-width: 460px;
  align-items: center;
}

.user {
  flex-grow: 0;
}

.menuMobile {
  padding: 0;
}

.menuMobile .wrapperLinkMenu {
  height: 50px;
  padding: 0;
}

.link,
.linkMenu {
  color: #6d7484;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  display: flex;
  height: 100%;
}

.link {
  margin: 0 5px;
  padding-left: 5px;
  padding-right: 5px;
  justify-content: center;
}

.linkMenu {
  flex-grow: 1;
  padding: 6px 16px;
}

.noCursor {
  cursor: default;
}

.active {
  color: #000a19;
}

.link.active::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000a19;
}

.linkMenu.active::after {
  content: '';
  position: absolute;
  left: 0;
  /* transform: translateX(-50%); */
  top: 5px;
  width: 2px;
  height: 50px;
  background-color: #000a19;
}

.burgerMenuIcon {
  fill: #000a19;
}

.userWrapper .btnUser {
  padding: 0;
}

.menuUserItem {
  width: 248px;
}

.menuIcon {
  width: 20px;
  height: 20px;
  fill: black;
}
