.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  box-sizing: border-box;
}

.iconFlex {
  display: flex;
  align-items: center;
  gap: 4px;
}
.iconBox {
  display: flex;
}
