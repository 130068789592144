.titleFontSize {
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 600;
  color: '424040';
  height: 40px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
