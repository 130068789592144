.searchInput {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding-right: 12px;
  background-color: #fff;
  height: 38px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
  padding-top: 4px;
  padding-bottom: 4px;
}
