.joystickWrapper {
  position: relative;
}

.joystickWrapper .joystickDirection {
  position: absolute;
  top: -23px;
  left: -29px;
  z-index: -1;
  width: 210px;
  height: 210px;
}

.joystickWrapper .joystickDirection.down {
  transform: rotate(180deg);
  top: -35px;
}

.joystickWrapper .joystickDirection path {
  stroke: #595959;
}

.joystickWrapper .joystickDirection path:first-child {
  stroke: #424040;
}

.joystickWrapper .joystickDirection.white path {
  stroke: #ffffff;
}

.joystickBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  left: -4px;
  top: -4px;
  border-radius: 100%;
  border: 4.5px solid #424040;
  z-index: -1;
}

.vertical {
  transform: rotate(90deg);
}
