.tableWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.disableBorder {
  border-bottom: 0 !important;
}
.menuItemIcon {
  margin: 0 12px;
}
