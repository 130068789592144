.root {
  position: relative;
  max-width: 60%;
  min-width: 320px;
  background: #fff;
  box-shadow: 0 4px 40px rgba(13, 28, 51, 0.16);
  border-radius: 2px;
  outline: none;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 0px 35px 32px 35px;
}

@media only screen and (max-height: 450px) {
  .title {
    font-weight: 500;
    font-size: 22px;
    color: #000a19cc;
    margin-top: 27px;
    margin-bottom: 7px;
    line-height: 15px;
  }
}

@media only screen and (min-height: 450px) {
  .title {
    font-weight: 500;
    font-size: 32px;
    color: #000a19cc;
    margin-top: 37px;
    margin-bottom: 17px;
    line-height: 25px;
  }
}

@media only screen and (max-height: 450px) {
  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 15px 0px 0px 0px;
  }
}

@media only screen and (min-height: 450px) {
  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 43px 0px 0px 0px;
  }
}

.btn {
  max-width: 160px;
  margin-left: 16px;
}

.btnRed {
  background-color: rgb(208, 64, 64);
  border: 1px solid rgb(208, 64, 64);
}

.btnRed:hover {
  background-color: rgba(208, 64, 64, 0.752);
  border: 1px solid rgba(208, 64, 64, 0.752);
}

.titleBlock {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
