.main {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1 1 auto;
  min-height: 0;
  min-width: 650px;
}

.leftBlock {
  margin-right: 27px;
  flex: 1 1 553px;
  display: flex;
  flex-direction: column;
}

.map {
  flex: 1 1 800px;
  border-radius: 2px;
  overflow: hidden;
}

.box {
  padding: 0px;
  display: flex;
  min-height: 0;
  /* background-color: #e4e5e8; */
  border-radius: 4px;
}

.loader {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  margin-right: 25px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
